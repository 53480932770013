.cellGreen {
    background-color: #00c853 !important;
}

.stornorow {
    color: rgb(166, 179, 189) !important;
}



.ant-input-search > .ant-input {
    min-height: 0px !important;
}
.ant-input-suffix > .ant-input-search-button {
    margin-top: -10px;
    line-height: 1px;
    height: 31px;
    border-radius: 0px 4px 4px 0px !important;
}

.round.ant-btn {
    border-radius: 4px !important;
}

/*.ant-input-prefix {
     margin-top: -6px;
     line-height: 1px;
 }*/

.ant-input-prefix:hover {
    cursor: pointer;
}

.ant-table td { white-space: nowrap; }





.ShowAgreement-View {
    height: 100%;
    max-height: 65vh;
    overflow: auto;
}




.sigCanvas {
    border: 1px solid lightgray;
    border-radius: 5px;
}

.signContainer {
    position: relative;
}

.signContainer:before {
    content: ' ';
    position: absolute;
    display: inline-block;
    width: 90%;
    margin: 0px 5%;
    top: 50%;
    background-color: black;
    height: 2px;
}


.signResetBtn {
    display: block;
    margin-left: auto;
    margin-bottom: 10px;
}

.signAgreement-selection {
    padding: 10px;
}

.signAgreement-selection > label {
    display: inline-block;
    width: 300px;
}


.signAgreement-error {
    color: red;
    font-weight: bold;
}