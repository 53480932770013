.ReservationNoteWrapper {
    display: flex;
    flex-direction: column-reverse; /* auto scroll to bottom hack */
    padding: 10px;
    gap: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 650px;
    max-height: 400px;
    margin: 0 auto;
    overflow: auto;
}

.ReservationNote {
    display: flex;
    flex-direction: column;
    background: #efefef;
    border-radius: 5px;
    padding: 10px;
}

.ReservationNoteHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.ReservationNoteHeader > .ReservationNoteAvatar {
    width: 40px;
}

.ReservationNoteHeader > .ReservationNoteTitle {
    flex: 1;
}

.ReservationNoteBody {
    margin-left: 42px;
    flex: 1;
    flex-direction: column;
}
.ReservationNoteBody > * {
    display: block;
    flex: 1;
}

.ReservationNoteInputWrapper {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 650px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.ReservationNoteInputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.ReservationNoteInputContainer {
    flex: 1;
    display: flex;
    gap: 10px;
    flex-direction: row;
}

.ReservationNoteImage {
    object-fit: contain;
    cursor: pointer;
}