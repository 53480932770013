.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: #f52929 !important;
    color: #FFF !important;
}

.rbc-button-link {
    user-select: none;
}

.rbc-selected-cell {
    cursor: grabbing !important;
    background-color: rgba(245, 41,41 ,0.5) !important;
}


.ant-picker {
    width: 100%;
}