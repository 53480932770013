.cellGreen {
    background-color: #00c853 !important;
}

.stornorow {
    color: rgb(166, 179, 189) !important;
}



.ant-input-search > .ant-input {
    min-height: 0px !important;
}
.ant-input-suffix > .ant-input-search-button {
    margin-top: -10px;
    line-height: 1px;
    height: 31px;
    border-radius: 0px 4px 4px 0px !important;
}

.round.ant-btn {
    border-radius: 4px !important;
}

/*.ant-input-prefix {
     margin-top: -6px;
     line-height: 1px;
 }*/

.ant-input-prefix:hover {
    cursor: pointer;
}

.ant-table td { white-space: nowrap; }