.cellGreen {
    background-color: #00c853 !important;
}

.cellYellow {
    background-color: #fff815 !important;
}

.cellBlue {
    background-color: #08e9ff !important;
}

.cellDeepBlue {
    background-color: #0890ff !important;
}

.cellOrange {
    background-color: #fbc02d !important;
}

.cellRed {
    background-color: #fb5025 !important;
}

.today {
   border-left: 3px solid black !important;
   border-right: 3px solid black !important;
}

.changedReservation {
    border-left: 5px solid red !important;
}

.jobs {
    position: relative;
}

.jobs:before {
    position: absolute;
    top: 0px;
    right: 0px;
    /*box-shadow: 0 0 7px #b53;*/
    border-right: 25px solid red;
    border-bottom: 25px solid transparent;
    content: ''
}

.roomTitle > span {
    font-size: 0.8rem;
}

.roomTitle > span:first-child {
    font-weight: bolder;
}


.ant-input-search > .ant-input {
    min-height: 0px !important;
}
.ant-input-suffix > .ant-input-search-button {
    margin-top: -10px;
    line-height: 1px;
    height: 31px;
    border-radius: 0px 4px 4px 0px !important;
}

.round.ant-btn {
    border-radius: 4px !important;
}

/*.ant-input-prefix {
     margin-top: -6px;
     line-height: 1px;
 }*/

.ant-input-prefix:hover {
    cursor: pointer;
}

.roomplanTable .ant-table td { white-space: nowrap; }

.roomplanTable .ant-table-tbody>tr>td,
.roomplanTable .ant-table-thead>tr>th {
    padding: 5px 16px !important;
}

#monthPicker .ant-calendar-picker-clear, #monthPicker .ant-calendar-picker-icon {
    margin-top: -11px;
}

.dynamic-delete-button:hover {
    cursor: pointer;
    color: red;
}